import './PricingPackage.scss';

const PricingPackage = ({ pack }) => {

  return (
    <div className='package-container'>
      <div className={`package-image package-image__${pack.id}`} />
      <div className="details-wrapper">
        <h2>{pack.title}</h2>
        <h3>{pack.price}</h3>
        <ul>
          {pack.list.map(item => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PricingPackage;