import './IntroSection.scss';
import { Link } from 'react-router-dom';


export const IntroSection = () => {
  return (
    <div className='intro-section' >
      <p className='intro-text'>Your <span>forever</span> starts here</p>
      <Link className="contact-button" to="/contact">Contact Lex</Link>
    </div>
  )
}