import './NavBar.scss';
import { Link } from 'react-router-dom';
import menuIcon from '../assets/images/menu.png';
import logo from '../assets/images/logo-small.png';
import { useState } from 'react';
export const NavBar = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const handleClick = () => {
    setShowDropdown(!showDropdown);
  }

  return (
    <div className='header'>
      <div className="header-container">
        <Link to="/">
          <img className="header-logo" src={logo} alt="Events by Lex" />
        </Link>
        <div onClick={handleClick}>
            <img className="dropdown-icon" src={menuIcon} />
        </div>
      </div>
      <div className='nav-container'>
        <nav className={`dropdown ${showDropdown ? 'active' : ''}`}>
          <ul>
            <li>
              <Link onClick={handleClick} className="link" to="/">HOME</Link>
            </li>
            <li>
              <Link onClick={handleClick} className="link" to="/#about-lex">ABOUT</Link>
            </li>
            <li>
              <Link onClick={handleClick} className="link" to="/pricing">PRICING</Link>
            </li>
            <li>
              <Link onClick={handleClick} className="link" to="/contact">CONTACT</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}