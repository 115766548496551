import { AboutSection } from '../components/AboutSection';
import { Footer } from '../components/Footer';
import { IntroSection } from '../components/IntroSection';
import { PricingSection } from '../components/PricingSection';

const HomePage = () => {
  return (
    <div className="app">
      <IntroSection />
      <AboutSection />
      <PricingSection />
      <Footer />
    </div>
  )
};

export default HomePage;