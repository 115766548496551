export const pricingPackages = [
  {
    title: "Week of Coordinating",
    price: "$875",
    list: [
      "10 hours of on-site coordination",
      "30 days of unlimited email communication",
      "Details meeting for up to 2 hours",
      "Communication with all vendors",
      "Assisting vendors with timeline and vendor specific details day of.",
      "Day of Wedding Outline Binder",
      "Maintain timeline and ensure wedding day is organized and efficient",
      "Delegate timeline created by bride",
      "Wedding Portal dedicated for files and communication",
    ],
    id: 0,
  },
  {
    title: "Month of Coordinating",
    price: "$1250",
    list: [
      "10 hours of on-site coordination",
      "45 days of unlimited email communication",
      "Details meeting for up to 2 hours",
      "Customize a wedding timeline",
      "Delegate timeline",
      "Communication with all vendors",
      "Assisting vendors with timeline and vendor specific details day of",
      "Maintain timeline and ensure wedding day is organized and efficient",
      "Wedding Outline Binder",
      "Wedding Portal dedicated for files and communication",
    ],
    id: 1,
  },
  {
    title: "3 Months",
    price: "$1850",
    list: [
      "10 hours of on-site coordination",
      "3 months of unlimited email communication",
      "3 meetings up to an hour each within 90 days",
      "Customize a wedding timeline",
      "Table decor set up *tear down not included",
      "Wedding Outline Binder",
      "Communication with all vendors",
      "Assisting vendors with timeline and vendor specific details day of",
      "Maintain timeline and ensure wedding day is organized and efficient",
      "Wedding Portal dedicated for files and communication",
    ],
    id: 2,
  },
  {
    title: "Partial Planning 6 Months",
    price: "$2850",
    list: [
      "6 months of unlimited communication",
      "12 hours of on-site coordination",
      "Communication with all vendors",
      "Customize a wedding timeline",
      "Wedding Outline Binder",
      "Assistant coordinator included",
      "Assisting vendors with timeline and vendor specific details day of",
      "Maintain timeline and ensure wedding day is organized and efficient",
      "Wedding Portal dedicated for files and communication",
      "Table decor set up and tear down"
    ],
    id: 3,
  },
  {
    title: "Full Planning 12 Months",
    price: "$4000",
    list: [
      "12 hours of on-site coordination",
      "Unlimited phone calls",
      "5 details meetings",
      "Assistant coordinator included",
      "Customize a wedding timeline",
      "Table decor set up and tear down",
      "Communication with all vendors",
      "Wedding Outline Binder",
      "Assisting vendors with timeline and vendor specific details day of",
      "Maintain timeline and ensure wedding day is organized and efficient",
      "Wedding Portal dedicated for files and communication",
    ],
    id: 4,
  },
  {
    title: "A La Carte Services",
    list: [
      "Additional day of hours - $60 per hour",
      "Cake Cutting - $100",
      "Set up table decor - $125",
      "Rehearsal walk through (Travel Included up to 20miles) - $150",
      "Stationary + mailing invitations - $250",
      "Custom heart shaped name sunglasses - $8 per item",
    ],
    id: 5,
  },
];