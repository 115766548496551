import PricingPackage from "../components/PricingPackage";
import { pricingPackages } from "../data/pricingPackages";
import {Footer} from '../components/Footer';
import { Link } from 'react-router-dom';

const PricingPage = () => {
  return (
    <div className="pricing-page">
      <h1 className="packaging-header">Wedding Packages</h1>
      <div className="additional-details">
        <p>**Each package will come with a Lead Coordinator, an Assistant may attend depending on guest count. Each Bride will also have access to our day of wedding emergency kit, packed with all of the goodies you may need on your special day.</p>
      </div>
      <div className="grid-container">
        {pricingPackages.map(pack => <PricingPackage pack={pack} key={pack.id} />)}
      </div>
      <Link className="pricing-contact-button" to="/contact">Contact Lex</Link>
      <Footer />
    </div>
  )
}

export default PricingPage;