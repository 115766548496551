import { useRef, useState } from "react";
import emailjs from '@emailjs/browser'
import './Contact.scss'
import { SERVICE_ID, LEX_TEMPLATE_ID, PUBLIC_KEY } from "../data/EmailKeys";


const ContactPage = () => {
  const form = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    emailjs.sendForm(
      SERVICE_ID,
      LEX_TEMPLATE_ID,
      form.current,
      {
        publicKey: PUBLIC_KEY
      }
    ).then(() => {
      setIsError(false);
      setName('');
      setEmail('');
      setMessage('');
      setStateMessage('Message sent! A confirmation email has been sent to your inbox.');
      setIsSubmitting(false);
      setTimeout(() => {
        setStateMessage(null);
      }, 5000); // hide message after 5 seconds
      })
    .catch((error) => {
      console.log("error is", error);
      setIsError(true);
      setStateMessage('Something went wrong. Please try again later');
      setIsSubmitting(false);
      setTimeout(() => {
        setStateMessage(null);
      }, 5000); // hide message after 5 seconds
    })
  };
  return (
    <div className="contact-form">
      <header className="contact-header">
        <h1 className="contact-header__title">Contact Me</h1>
        <p>I would love to help plan your dream wedding or event. Please fill out the form below!</p>
      </header>
    {stateMessage && (
      <div className={`stateMessage ${isError ? "error" : ''}`}>{stateMessage}</div>
    )}
    <form ref={form} onSubmit={sendEmail}>
      <label>name</label>
      <input type="text" name="from_name" value={name} onChange={(e) => setName(e.target.value)}/>
      <label>email</label>
      <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <label>message</label>
      <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)}/>
      <input className="sendbutton" type="submit" value="submit" disabled={isSubmitting} />
      {stateMessage && <p>{stateMessage}</p>}
    </form>
    </div>
  );
};

export default ContactPage;