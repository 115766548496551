import './AboutSection.scss';
import { Link } from 'react-router-dom';

export const AboutSection = () => {
  return (
    <div className='about-section'>
      <h1 id="about-lex" className='about-lex'>About Me</h1>
        <div className='about-wrapper'>
          <div className='lex-image-container'>
            <div className="lex-image" />
          </div>
          <div className='text-wrapper'>
            <p className='about-text'>Hi! My name is Alexis, I am the lead coordinator for Events by Lex. First of all, congratulations on your engagement! What an amazing time in your life! Let me tell you a little bit about myself. I married my high school sweetheart in 2022 and shortly after tying the knot we received our precious gift, our son Caleb. As I began planning my own wedding, I discovered a burning passion and joy for the wedding industry. Since then, I have been in the industry for almost three years and helped couples have the wedding of their dreams. </p>
          </div>
      </div>
      <Link className="about-contact-button" to="/contact">Contact Lex</Link>
    </div>
  )
}