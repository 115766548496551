import './Footer.scss'
import logo from '../assets/images/logo-small.png'

export const Footer = () => {
  return (
    <div className="contact-section">
      <img className='logo-image' src={logo} />
      <div className='contacts-wrapper'>
        <span className='contact-name'>Alexis Serrano</span>
        <span className='contact-title'>Dallas Wedding Planner</span>
        <a className='contact-email' href={'mailto:alexis.weddingsandevents@gmail.com'}>alexis.weddingsandevents@gmail.com</a>
        <a className='telephone' href='tel:512-831-9471'>512-831-9471</a>
      </div>
    </div>
  )
}