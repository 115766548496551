import './PricingSection.scss'
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import fullPlanning from '../assets/images/pricing/full-planning.jpeg';
import partialPlanning6 from '../assets/images/pricing/partial-planning-6.jpeg';
import partialPlanning3 from '../assets/images/pricing/partial-planning-3.jpeg';
import monthPlanning from '../assets/images/pricing/month-planning.jpeg';
import weekPlanning from '../assets/images/pricing/week-planning.jpeg';

export const PricingSection = () => {
   const [numSlidesToShow, setNumSlidesToShow] = useState(1);

    useEffect(() => {
      const handleResize = () => {
        let numSlides = 1;
        if (window.innerWidth >= 600) {
          numSlides = 2;
        }
        if (window.innerWidth >= 1000) {
          numSlides = 3;
        }
        setNumSlidesToShow(numSlides);
      };
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <div className='pricing-section'>
      <h2 className='pricing-header'>Rates <span>&</span> Services</h2>
        <Swiper
          slidesPerView={numSlidesToShow}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={weekPlanning} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={monthPlanning} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={partialPlanning3} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={partialPlanning6} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={fullPlanning} />
          </SwiperSlide>
        </Swiper>
    </div>
  );
}